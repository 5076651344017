<template>
  <span class="inline-flex flex-row gap-2">
    <span v-if="!hideHeen" class="inline-flex gap-1">
      <button
        :disabled="!heenMemo"
        class="whitespace-nowrap btn smaller text-sm pt-0 pb-0 py-0 bg-gray-50 text-gray-400"
        :class="{ 'border-teal-400 bg-teal-200 text-teal-900 cursor-pointer': heenMemo }"
        @click="heenMemo && alertMemo(heenMemo)"
      >
        <template v-if="heenMemo">
          <span v-if="previewLength <= 0"><i class="fas fa-comment"></i> Memo heen</span>
          <TextTruncate v-else :length="previewLength">{{ heenMemo }}</TextTruncate>
        </template>
        <span v-else>Geen memo <span v-if="isRetour">heenreis</span></span>
      </button>
      <i v-show="!disabled" class="fas fa-edit text-teal-500 cursor-pointer" @click="onEdit"></i>
    </span>
    <span v-if="!hideRetour && isRetour" class="inline-flex gap-1">
      <button
        :disabled="!retourMemo"
        class="whitespace-nowrap btn smaller text-sm pt-0 pb-0 py-0 bg-teal-50"
        :class="{ 'bg-teal-300 text-teal-800': heenMemo }"
        @click="retourMemo && alertMemo(retourMemo)"
      >
        <template v-if="retourMemo">
          <span v-if="previewLength <= 0"><i class="fas fa-comment"></i> Memo retour</span>
          <TextTruncate v-else :length="previewLength">{{ retourMemo }}</TextTruncate>
        </template>
        <span v-else>Geen memo retour</span>
      </button>
      <i v-show="!disabled" class="fas fa-edit text-teal-500 cursor-pointer" @click="onEdit"></i>
    </span>
  </span>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'

import TextTruncate from '@/components/UI/Text/Truncate'

defineProps({
  hideHeen: Boolean,
  hideRetour: Boolean,
  heenMemo: String,
  retourMemo: String,
  disabled: Boolean,
  isRetour: Boolean,
  previewLength: {
    type: [Number, String],
    default: 50,
  },
})

const emit = defineEmits(['edit'])

const onEdit = event => {
  emit('edit', event)
}

const alertMemo = memo => {
  if (!memo || !memo.length) return
  window.alert(memo)
}
</script>
